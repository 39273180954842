.card {
  width: 500px;
  height: 300px;
  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include media(">=phone", "<lgphone") {
    width: 350px;
    height: 250px;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .content {
    font-family: "Open Sans";
    text-align: center;
    padding: 10px;
    // opacity: 0;
    // transition: all 500ms ease-in-out;
    // transform: translate(0, 2px);
    // cursor: pointer;

    h1 {
      font-size: 15px;
      background-color: white;
      color: black;
      padding: 10px;
      text-decoration: none;
      opacity: 0.8;
    }
    p {
      font-size: 15px;
      margin-bottom: 20px;
      background-color: white;
      color: black;
      opacity: 0.8;
      padding: 1px;
    }

    .btn {
      background-color: black;
      color: white;
      padding: 10px;
      text-decoration: none;
      opacity: 0.8;
      &:hover {
        text-decoration: underline;
        opacity: 1;
        transform: translate(0, 0px);
      }
    }
    &:hover {
      opacity: 1;
      transform: translate(0, 0px);
    }
  }
}
