.work-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 2.5rem;
    line-height: 20px;
  }
  .grid {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    @include media(">=phone", "<lgphone") {
      grid-template-columns: 1fr;
    }
    @include media(">=lgphone", "<tablet") {
      grid-template-columns: 1fr;
    }
    @include media(">=tablet", "<desktop") {
      grid-template-columns: 1fr 1fr;
    }
  }
}
