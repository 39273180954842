@charset "utf-8";
@import "./include-media.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans&display=swap");

// Card
@import "./card.scss";

// Components
@import "./navbar.scss";
@import "./hero.scss";
@import "./work.scss";
@import "./about.scss";
@import "./footer.scss";

* {
  font-family: "Montserrat";
}

body {
  //   background: linear-gradient(#fff, #fffdf7, #ffe9ec) !important;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.section {
  width: 100vw;
  height: auto;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.socialContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.primary-btn {
  margin: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  border: 0px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}
@media screen and (max-width: 35.5em) {
  .primary-btn {
    margin: 5px;
    padding: 4px;
    background-color: black;
    color: white;
    border: 0px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  @media screen and (min-width: 35.5em) {
    .primary-btn {
      margin: 5px;
      padding: 5px;
      background-color: black;
      color: white;
      border: 0px;
      transition: all 0.2s ease-in-out;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
