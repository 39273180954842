.footer-container {
  width: 80vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 2.5rem;
    line-height: 20px;
    @include media(">=phone", "<lgphone") {
      font-size: 2.5rem;
    }
  }
  h2 {
    font-size: 1.5rem;
    overflow: hidden;
    @include media(">=phone", "<lgphone") {
      display: none;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 14px;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 1.2rem;
    }
  }
  .email-link {
    font-size: 1.5rem;
    color: black;
    @include media(">=phone", "<lgphone") {
      font-size: 1.5rem;
      padding-bottom: 10px;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 14px;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 1.2rem;
    }
  }
  .social-icons {
    margin-top: 20px;
    img {
      height: 2rem;
      margin: 10px;
    }
  }
  span {
    margin-top: 10px;
    @include media(">=phone", "<lgphone") {
      font-size: 0.95rem;
    }
    @include media(">=lgphone", "<tablet") {
      font-size: 0.95rem;
    }
    @include media(">=tablet", "<desktop") {
      font-size: 0.95rem;
    }
  }
  icon {
    color: #ffc600;
  }
}
