.navbar-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
    font-size: 20px;
    font-weight: 600;
  }
  .links-wrapper {
    button {
      text-decoration: none;
      padding: 10px;
      font-family: "Open Sans";
      opacity: 0.6;
      transition: all 0.2s ease-in-out;
      font-size: 18px;
      background-color: transparent;
      outline: none;
      border: 0px;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .active {
      color: black;
    }
  }
}
